/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.modal-xl > .modal-content {
  min-height: 50vh;
}


/* .modal-dialog {
min-height: calc(100vh - 60px);
display: flex;
flex-direction: column;
justify-content: center;
overflow: auto;
} */

@media(min-width: 768px) {
  /* .modal-dialog {
    min-height: calc(100vh - 20px);
  } */

  .modal-xl > .modal-content {
    min-height: 80vh;
  }
}

@media (min-width: 700px) {
  .modal-lg, .modal-xl {
      width: 650px !important;
      max-width: 650px !important;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
      width: 900px !important;
      max-width: 900px !important;
  }
}

@media (max-width: 720px) {
  .container, .container-sm {
      max-width: 650px !important;
  }
}

.statusbar-right,
.statusbar-left {
  position: fixed;
  bottom: 0px;
  left: 0px;
  /* display: inline-block; */
  margin: 0px;
  padding: 5px 5px;
  background-color: #efefef;
  border-top-right-radius: 10px;
}

.statusbar-right li,
.statusbar-left li {
  display: inline-block;
  padding: 0px 5px;
}

.statusbar-right {
  right: 0px;
  left: unset;
  border-top-right-radius: 0px;
  border-top-left-radius: 10px;
}

.statusbar-right .version {
  font-size: 10px;
  font-weight: bold;
}


body {
  padding-bottom: 30px;
}


form .custom-control-label::before,
form .custom-control-label::after {
    width: 2rem;
    height: 2rem;
    left: -44px;
}

.modal-xl button.close {
  padding: 11px 22px;
  font-size: 2.5em;
}

.modal-body {
  padding: 30px !important;
  /* max-height: 50vh; */
}

label.required::before {
  content: '* ';
  color: #FF0000;
}

.navbar a {
  color: #9b2524;
}

div.dashboard .card {
  background-color: #9b2524;
  color: #FFF;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(221,221,221,1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(221,221,221,1);
  box-shadow: 0px 0px 5px 0px rgba(221,221,221,1);
}
div.dashboard .card-title {
  font-size: 30px;
  font-weight: 900;
}


.navbar ul.nav.name {
  font-size: 20px;
  font-weight: bold;
}

li.nav-item div.nav-link div.explain {
  height: 18px;
  width: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
  border-radius: 4px;
  margin-top: -2px;
  border: 1px solid #00000020;
}


.btn-group-lg>.btn, .btn-lg {
  padding: .7rem 1.2rem;
  font-size: 1.50rem;
}


.system-backdrop {
  z-index: 9999;
}


li.billing-info p {
  margin-bottom: 0px;
}
