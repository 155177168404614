body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:not([href]),
a:not([href]):hover {
  color: #9b2524;
}

.dashboard .card-deck a.card:not([href]),
.dashboard .card-deck a.card:not([href]):hover {
  color: #FFF !important;
  text-decoration: none;
  cursor: pointer;
}


button.btn-xs {
  font-size: 12px;
  padding: 4px 8px;
}


/* .react-datepicker-wrapper {
  width: 100%;
} */

.react-datepicker {
  font-size: 1.0rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.6rem;
  line-height: 2.2rem;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-weight: bold;
  font-size: 1rem;
}


.react-datepicker__navigation {
  border-left-width: 0.6rem;
  border-right-width: 0.6rem;
  border-top-width: 0.6rem;
  border-bottom-width: 0.6rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 34px;
}


table.rbc-agenda-table td.rbc-agenda-date-cell {
  background-color: #FFF;
  color: #000;
}









.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.checkbox > span {
  padding: 0rem 0.4rem;
}

.checkbox > input {
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #CCC;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  margin-top: -2px;
}

/* .checkbox > input:checked {
  border: 1px solid rgb(248, 215, 218);
  background-color: #34495E;
} */

.checkbox > input:checked + span::before {
  content: '\2713';
  position: absolute;
  left: -16px;
  top: -3px;
  font-size: 14px;
}

.checkbox.checkbox-danger > input {
  background-color: rgb(248, 215, 218);
}

.checkbox.checkbox-warning > input {
  background-color: rgb(255, 243, 205);
}

.checkbox.checkbox-success > input {
  background-color: rgb(212, 237, 218);
}

.checkbox.checkbox-light > input {
  background-color: rgb(248, 249, 250);
}
